import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../component/MyContext';

import '../styles/mystyle.css';
//import '../styles/safahat.module.css';

function Safahat() {
  const {safha_set} = useMyContext();
  const navigate = useNavigate();

  function SafahatList({safha_count}) {
    function safha_click(safha) {
      safha_set(safha);
      navigate(-1);
    }

    const boutons = [];
    for (let i = 1; i <= safha_count; i++) {
      boutons.push(
        <button key={i} onClick={() => safha_click(i)} className='btn_safahat'>
          {i} 
        </button>
      );
    }
    return (<div className='list'> {boutons} </div>);
  }

  function ReturnButton() {
    function return_click(e) {
      e.preventDefault();
      navigate(-1);
    }
    return (<button onClick={return_click}>رجوع</button>)
  }

  return (
    <div className='col'>
      <ReturnButton />
      <SafahatList safha_count = {604} />
    </div>
  );
}

export default Safahat;