import Cookies from 'js-cookie';

function set_cookie (name, value) {
  Cookies.set(name, value, { expires: 1000 });
};

function get_cookie (name) {
  const value = Cookies.get(name);
  return value;
};
/*
const remove_cookie = (name) => {
  Cookies.remove(name);
};
*/
export function load_cookies(sura_set,aaya_set,safha_set) {
  const sn = get_cookie('sura');
  const an = get_cookie('aaya');
  const sh = get_cookie('safha');
  sura_set (sn);
  aaya_set (an);
  safha_set(sh);
}

export function save_cookies(sura,aaya,safha) {
  set_cookie('sura' , sura );
  set_cookie('aaya' , aaya );
  set_cookie('safha', safha);
}

