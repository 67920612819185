import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../component/MyContext';
import '../styles/mystyle.css';
//import '../styles/fahras.module.css';

function Fahras() {

  const navigate = useNavigate();
  //const { sura_set, aaya_set, suralist } = useMyContext();
  const {safha_set,suralist} = useMyContext();

  function SuratPaint({ sura_list }) {
    function sura_click(sura_no) {
      //sura_set(sura_no);
      //aaya_set(1);
      safha_set(suralist[sura_no-1][2]);
      navigate(-1);
    }

    const rows = sura_list.map(item => Object.values(item));
    const boutons = [];
    for (let i = 0; i < sura_list.length; i++) {
      boutons.push(
        <button key={i} onClick={() => sura_click(i + 1)} className='btn_fahres'>
          {rows[i][0]} - {rows[i][3]} ({rows[i][1]})
        </button>
      );
    }
    return (<div className='list'> {boutons} </div>);
  }

  function ReturnButton() {
    function return_click(e) {
      e.preventDefault();
      navigate(-1);
    }
    return (<button onClick={return_click}>رجوع</button>)
  }

  return (
    <div className='col'>
      <ReturnButton />
      <SuratPaint sura_list={suralist} />
    </div>
  );
}

export default Fahras;