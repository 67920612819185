import React, { createContext, useContext, useState } from 'react';

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const [sura, sura_set] = useState(1);
  const [aaya, aaya_set] = useState(1);
  const [safha, safha_set] = useState(1);

  const [suralist, suralist_set] = useState([ // sura no , sura name , aaya count, sura first page
   /*
        [  1, 'الفاتحة'  ,  7],[  2, 'البقرة'   ,286],[  3, 'آل عمران' ,200],[  4, 'النساء'   ,176],[  5, 'المائدة'  ,120],[  6, 'الأنعام'   ,165],
        [  7, 'الأعراف'   ,206],[  8, 'الأنفال'   , 75],[  9, 'التوبة'   ,129],[ 10, 'يونس'     ,109],[ 11, 'هود'      ,123],[ 12, 'يوسف'     ,111],
        [ 13, 'الرعد'    , 43],[ 14, 'إبراهيم'  , 52],[ 15, 'الحجر'    , 99],[ 16, 'النحل'    ,128],[ 17, 'الإسراء'   ,111],[ 18, 'الكهف'    ,110],
        [ 19, 'مريم'     , 98],[ 20, 'طه'       ,135],[ 21, 'الأنبياء'  ,112],[ 22, 'الحج'     , 78],[ 23, 'المؤمنون' ,118],[ 24, 'النور'    , 64],
        [ 25, 'الفرقان'  , 77],[ 26, 'الشعراء'  ,227],[ 27, 'النمل'    , 93],[ 28, 'القصص'    , 88],[ 29, 'العنكبوت' , 69],[ 30, 'الروم'    , 60],
        [ 31, 'لقمان'    , 34],[ 32, 'السجدة'   , 30],[ 33, 'الأحزاب'   , 73],[ 34, 'سبأ'      , 54],[ 35, 'فاطر'     , 45],[ 36, 'يس'       , 83],
        [ 37, 'الصافات'  ,182],[ 38, 'ص'        , 88],[ 39, 'الزمر'    , 75],[ 40, 'غافر'     , 85],[ 41, 'فصلت'     , 54],[ 42, 'الشورى'   , 53],
        [ 43, 'الزخرف'   , 89],[ 44, 'الدخان'   , 59],[ 45, 'الجاثية'  , 37],[ 46, 'الأحقاف'   , 35],[ 47, 'محمد'     , 38],[ 48, 'الفتح'    , 29],
        [ 49, 'الحجرات'  , 18],[ 50, 'ق'        , 45],[ 51, 'الذاريات' , 60],[ 52, 'الطور'    , 49],[ 53, 'النجم'    , 62],[ 54, 'القمر'    , 55],
        [ 55, 'الرحمن'   , 78],[ 56, 'الواقعة'  , 96],[ 57, 'الحديد'   , 29],[ 58, 'المجادلة' , 22],[ 59, 'الحشر'    , 24],[ 60, 'الممتحنة' , 13],
        [ 61, 'الصف'     , 14],[ 62, 'الجمعة'   , 11],[ 63, 'المنافقون', 11],[ 64, 'التغابن'  , 18],[ 65, 'الطلاق'    , 12],[ 66, 'التحريم'  , 12],
        [ 67, 'الملك'    , 30],[ 68, 'القلم'    , 52],[ 69, 'الحاقة'   , 52],[ 70, 'المعارج'  , 44],[ 71, 'نوح'      , 28],[ 72, 'الجن'     , 28],
        [ 73, 'المزمل'   , 20],[ 74, 'المدثر'   , 56],[ 75, 'القيامة'  , 40],[ 76, 'الإنسان'   , 31],[ 77, 'المرسلات'  , 50],[ 78, 'النبأ'    , 40],
        [ 79, 'النازعات' , 46],[ 80, 'عبس'      , 42],[ 81, 'التكوير'  , 29],[ 82, 'الإنفطار'  , 19],[ 83, 'المطففين' , 36],[ 84, 'الإنشقاق'  , 25],
        [ 85, 'البروج'   , 22],[ 86, 'الطارق'   , 17],[ 87, 'الأعلى'    , 19],[ 88, 'الغاشية'  , 26],[ 89, 'الفجر'    , 30],[ 90, 'البلد'    , 20],
        [ 91, 'الشمس'    , 15],[ 92, 'الليل'    , 21],[ 93, 'الضحى'    , 11],[ 94, 'الشرح'    ,  8],[ 95, 'التين'    ,  8],[ 96, 'العلق'    , 19],
        [ 97, 'القدر'    ,  5],[ 98, 'البينة'   ,  8],[ 99, 'الزلزلة'  ,  8],[100, 'العاديات' , 11],[101, 'القارعة'  , 11],[102, 'التكاثر'  ,  8],
        [103, 'العصر'    ,  3],[104, 'الهمزة'   ,  9],[105, 'الفيل'    ,  5],[106, 'قريش'     ,  4],[107, 'الماعون'  ,  7],[108, 'الكوثر'   ,  3],
        [109, 'الكافرون' ,  6],[110, 'النصر'    ,  3],[111, 'المسد'    ,  5],[112, 'الإخلاص'    ,  4],[113, 'الفلق'    ,  5],[114, 'الناس'    ,  6],
*/
 
    [1, 7, 1, 'الفاتحة'],
    [2, 286, 2, 'البقرة'],
    [3, 200, 50, 'آل عمران'],
    [4, 176, 77, 'النساء'],
    [5, 120, 106, 'المائدة'],
    [6, 165, 128, 'الأنعام'],
    [7, 206, 151, 'الأعراف'],
    [8, 75, 177, 'الأنفال'],
    [9, 129, 187, 'التوبة'],
    [10, 109, 208, 'يونس'],
    [11, 123, 221, 'هود'],
    [12, 111, 235, 'يوسف'],
    [13, 43, 249, 'الرعد'],
    [14, 52, 255, 'إبراهيم'],
    [15, 99, 262, 'الحجر'],
    [16, 128, 267, 'النحل'],
    [17, 111, 282, 'الإسراء'],
    [18, 110, 293, 'الكهف'],
    [19, 98, 305, 'مريم'],
    [20, 135, 312, 'طه'],
    [21, 112, 322, 'الأنبياء'],
    [22, 78, 332, 'الحج'],
    [23, 118, 342, 'المؤمنون'],
    [24, 64, 350, 'النور'],
    [25, 77, 359, 'الفرقان'],
    [26, 227, 367, 'الشعراء'],
    [27, 93, 377, 'النمل'],
    [28, 88, 385, 'القصص'],
    [29, 69, 396, 'العنكبوت'],
    [30, 60, 404, 'الروم'],
    [31, 34, 411, 'لقمان'],
    [32, 30, 415, 'السجدة'],
    [33, 73, 418, 'الأحزاب'],
    [34, 54, 428, 'سبأ'],
    [35, 45, 434, 'فاطر'],
    [36, 83, 440, 'يس'],
    [37, 182, 446, 'الصافات'],
    [38, 88, 453, 'ص'],
    [39, 75, 458, 'الزمر'],
    [40, 85, 467, 'غافر'],
    [41, 54, 477, 'فصلت'],
    [42, 53, 483, 'الشورى'],
    [43, 89, 489, 'الزخرف'],
    [44, 59, 496, 'الدخان'],
    [45, 37, 499, 'الجاثية'],
    [46, 35, 502, 'الأحقاف'],
    [47, 38, 507, 'محمد'],
    [48, 29, 511, 'الفتح'],
    [49, 18, 515, 'الحجرات'],
    [50, 45, 518, 'ق'],
    [51, 60, 520, 'الذاريات'],
    [52, 49, 523, 'الطور'],
    [53, 62, 526, 'النجم'],
    [54, 55, 528, 'القمر'],
    [55, 78, 531, 'الرحمن'],
    [56, 96, 534, 'الواقعة'],
    [57, 29, 537, 'الحديد'],
    [58, 22, 542, 'المجادلة'],
    [59, 24, 545, 'الحشر'],
    [60, 13, 549, 'الممتحنة'],
    [61, 14, 551, 'الصف'],
    [62, 11, 553, 'الجمعة'],
    [63, 11, 554, 'المنافقون'],
    [64, 18, 556, 'التغابن'],
    [65, 12, 558, 'الطلاق'],
    [66, 12, 560, 'التحريم'],
    [67, 30, 562, 'الملك'],
    [68, 52, 564, 'القلم'],
    [69, 52, 566, 'الحاقة'],
    [70, 44, 568, 'المعارج'],
    [71, 28, 570, 'نوح'],
    [72, 28, 572, 'الجن'],
    [73, 20, 574, 'المزمل'],
    [74, 56, 575, 'المدثر'],
    [75, 40, 577, 'القيامة'],
    [76, 31, 578, 'الإنسان'],
    [77, 50, 580, 'المرسلات'],
    [78, 40, 582, 'النبأ'],
    [79, 46, 583, 'النازعات'],
    [80, 42, 585, 'عبس'],
    [81, 29, 586, 'التكوير'],
    [82, 19, 587, 'الإنفطار'],
    [83, 36, 587, 'المطففين'],
    [84, 25, 589, 'الإنشقاق'],
    [85, 22, 590, 'البروج'],
    [86, 17, 591, 'الطارق'],
    [87, 19, 591, 'الأعلى'],
    [88, 26, 592, 'الغاشية'],
    [89, 30, 593, 'الفجر'],
    [90, 20, 594, 'البلد'],
    [91, 15, 595, 'الشمس'],
    [92, 21, 595, 'الليل'],
    [93, 11, 596, 'الضحى'],
    [94, 8, 596, 'الشرح'],
    [95, 8, 597, 'التين'],
    [96, 19, 597, 'العلق'],
    [97, 5, 598, 'القدر'],
    [98, 8, 598, 'البينة'],
    [99, 8, 599, 'الزلزلة'],
    [100, 11, 599, 'العاديات'],
    [101, 11, 600, 'القارعة'],
    [102, 8, 600, 'التكاثر'],
    [103, 3, 601, 'العصر'],
    [104, 9, 601, 'الهمزة'],
    [105, 5, 601, 'الفيل'],
    [106, 4, 602, 'قريش'],
    [107, 7, 602, 'الماعون'],
    [108, 3, 602, 'الكوثر'],
    [109, 6, 603, 'الكافرون'],
    [110, 3, 603, 'النصر'],
    [111, 5, 603, 'المسد'],
    [112, 4, 604, 'الإخلاص'],
    [113, 5, 604, 'الفلق'],
    [114, 6, 604, 'الناس'],
  ]);

  const contextValue = {
    sura, sura_set
    , aaya, aaya_set
    , suralist, suralist_set
    , safha, safha_set
  };

  return [
    <MyContext.Provider value={contextValue}>
      {children}
    </MyContext.Provider>
  ];
};

export const useMyContext = () => useContext(MyContext);

export default MyContextProvider;
