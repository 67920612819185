//import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom"

import Home from "./pages/Home"
import Explor from "./pages/Explor"
import Search from "./pages/Search"
import Fahras from "./pages/Fahras"
import Safahat from './pages/Safahat'
import Duaa from "./pages/Duaa"
import Study from './pages/Study'
import Riwayat from './pages/Riwayat'
import NoPage from "./pages/NoPage"

import ComponLife from './component/Events'

import './styles/mystyle.css'
import './styles/mainmenu.css'

function App() {
  async function handleLoad(event) {
    console.log("START");
  };

  async function handleBeforeUnload(event) {
    console.log("STOP");
  }

  const Navig = () => {
    return (
      <div>
        <nav className='row'>
          <ul>
            <li> <Link to="/"        >مـــرحــبا    </Link> </li>
            <li> <Link to="/explor"  >تـــصــفـــح  </Link> </li>
            <li> <Link to="/search"  >بــحــث       </Link> </li>
            <li> <Link to="/duaa"    >دعـــــاء     </Link> </li>
            <li> <Link to="/study"   >study          </Link> </li>
            <li> <Link to="/riwayat" >روايات         </Link> </li>
          </ul>
        </nav>
        <Outlet />
      </div>
    )
  };

  return (
    <div>
      <ComponLife handleload={handleLoad} beforeunload={handleBeforeUnload} />
      <BrowserRouter>
        <Routes>
          <Route path="/"         element={<Navig />}>
            <Route index          element={<Home />} />
            <Route path="explor"  element={<Explor />} />
            <Route path="fahras"  element={<Fahras />} />
            <Route path="safahat" element={<Safahat />} />
            <Route path="search"  element={<Search />} />
            <Route path="duaa"    element={<Duaa />} />
            <Route path="study"   element={<Study />} />
            <Route path="riwayat" element={<Riwayat />} />
            <Route path="*"       element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter >
    </div>
  );
}
export default App;


