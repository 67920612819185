import React, { useState, useEffect } from 'react';
import MsTable from '../component/MsTable';
import { request_explor } from '../web';

const Duaa = () => {
  const [page, setPage] = useState("");
  const [duaa, setDuaa] = useState([]);
  const headers = ['','الدعــاء','المصدر'];

  useEffect(() => {
    request_duaa();
  }, []);

  useEffect(() => {
    display_duaa();
  }, [duaa]);

  async function request_duaa() {
    var data, error;
    [data, error] = await request_explor({ "code": 300 })
    if (error !== "") {
      alert(error)
      return
    }
    setDuaa(data);
  }

  function display_duaa() {
    setPage(<MsTable head={headers} data={duaa} />);
  }

  return (
    <>
      <div className='col'>
        <br />
        <div> {page} </div>
      </div>
    </>
  )
};

export default Duaa;