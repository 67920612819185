import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMyContext } from '../component/MyContext'
//import FontSelector from '../component/FontSelector'
//import RiwayaSelector from '../component/RiwayaSelector'
import MsTable from '../component/MsTable'
import { load_cookies, save_cookies } from '../cookies'
import { request_explor } from '../web'
import '../styles/mystyle.css'

function Explor() {
  const { sura, sura_set, aaya, aaya_set, safha, safha_set } = useMyContext();

  const [page, setPage] = useState("");
  const [ayat, ayat_set] = useState([]);
  const [font, font_set] = useState('Arabic Typesetting');
  const [riwaya, riwaya_set] = useState("1");

  useEffect(
    () => {
      const headers = ['سورة', 'آية', 'نص الآيــة'];
      setPage(<MsTable head={headers} data={ayat} />)
    }
    , [ayat, font])
  /*
    useEffect(() => {
      request_page(riwaya, sura, aaya)
    }, [sura, aaya, riwaya])
  */

  useEffect(
    () => {
      request_safha(riwaya, safha);
    }
    , [riwaya, safha]
  )

  /*
    useEffect(
      () => {
        console.log("loading cookies")
        loadCookies();
        return () => {
          console.log("saving cookies")
          saveCookies()
        };
      //}, []
      }, 
    );
   */

  const handleFontChange = (font) => {
    font_set(font)
  }


  /*
    async function request_page(riwaya, sura, aaya) {
      var data, error
      const query = { "code": 2, "task": 0, "riwaya": riwaya, "sura": sura, "aaya": aaya };
      [data, error] = await request_explor(query)
      if (error !== "") {
        alert(error)
        return
      }
      ayat_set(data)
    }
  */
  async function request_safha(riwaya, safha) {
    var data, error
    const query = { "code": 10, "task": 0, "riwaya": riwaya, "safha": safha };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error)
      return
    }
    //console.log(data);
    ayat_set(data)
  }

  /*
  async function request_index(task, sura, aaya) {
    var data, error
    const query = { "code": 2, "task": task, "sura": sura, "aaya": aaya };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error)
      return
    }
    sura_set(data.sura)
    aaya_set(data.aaya)
  }
*/

  async function request_safha_index(task, riwaya, safha) {
    var data, error
    const query = { "code": 10, "task": task, "riwaya": riwaya, "safha": safha };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error)
      return
    }
    safha_set(data.safha);
  }


  function NextPage() {
    async function handleClick(e) {
      //await request_index(1, sura, aaya);
      await request_safha_index(1, riwaya, safha)
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevPage() {
    async function handleClick(e) {
      //await request_index(-1, sura, aaya);
      await request_safha_index(-1, riwaya,safha)
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function GetFahras() {
    const navigate = useNavigate()
    function handleClick(e) {
      navigate('/fahras')
    }
    return (
      <div>
        <button onClick={handleClick}>فهرس</button>
      </div>
    )
  }

  function GetSafha() {
    const navigate = useNavigate()
    function handleClick(e) {
      navigate('/safahat')
    }
    return (
      <div>
        <button onClick={handleClick}>صفحات</button>
      </div>
    )
  }


  function DisplayPage() {
    return (
      <div style={{ fontFamily: font }}>
        {page}
      </div>
    )
  }

  function BtnLoad() {
    async function handleClick() {
      load_cookies(sura_set, aaya_set, safha_set);
    }
    return (<div><button onClick={handleClick}> load </button></div>);
  };

  function BtnSave() {
    async function handleClick() {
      save_cookies(sura, aaya, safha);
      alert('saved');
    }
    return (<div><button onClick={handleClick}> save </button></div>);
  };

  function RiwayaSelector ({ selectedRiwaya, onRiwayaChange }) {
    const handleRiwayaChange = (event) => {
      onRiwayaChange(event.target.value);
    };
    return (
      <div>
        {/* <label htmlFor="fontSelector">Select a font: </label> */}
        <select id="riwayaSelector" value={selectedRiwaya} onChange={handleRiwayaChange}
          style={{ width: 50, fontSize: 10 }}>
          {/* <option value="00"> الرواية </option> */}
          <option value="01">حفص </option>
          <option value="02">ورش </option>
          <option value="03">بزّي </option>
          <option value="04">دوري </option>
          <option value="05">قالون</option>
          <option value="06">قنبل </option>
          <option value="07">شعبة </option>
          <option value="08">سوسي </option>
        </select>
      </div>
    );
  };
  
  async function handleRiwayaChange(riwaya) {
    riwaya_set(riwaya)
    switch (riwaya) {
      case "01": font_set("KFGQPC HafsEx1 Uthmanic Script"); break;
      case "02": font_set("KFGQPC WARSH Uthmanic Script"  ); break;
      case "03": font_set("KFGQPC BAZZI Uthmanic Script"  ); break;
      case "04": font_set("KFGQPC DOORI Uthmanic Script"  ); break;
      case "05": font_set("KFGQPC QALOON Uthmanic Script" ); break;
      case "06": font_set("KFGQPC QUMBUL Uthmanic Script" ); break;
      case "07": font_set("KFGQPC SHOUBA Uthmanic Script" ); break;
      case "08": font_set("KFGQPC SOOSI Uthmanic Script"  ); break;
      default: // code block
    }
  }

  function FontSelector ({ selectedFont, onFontChange }) {
    const handleFontChange = (event) => {
       onFontChange(event.target.value);
    };
    return (
       <div>
          {/* <label htmlFor="fontSelector">Select a font: </label> */}
          <select id="fontSelector" value={selectedFont} onChange={handleFontChange}
             style={{ width: 100, fontSize: 10 }}>
             {/* <option value="الخـــط"> الخـــط </option> */}
             <option value="KFGQPC HafsEx1 Uthmanic Script">HafsEx1</option>
             <option value="KFGQPC WARSH Uthmanic Script"  >WARSH  </option>
             <option value="KFGQPC BAZZI Uthmanic Script"  >BAZZI  </option>
             <option value="KFGQPC DOORI Uthmanic Script"  >DOORI  </option>
             <option value="KFGQPC QALOON Uthmanic Script" >QALOON </option>
             <option value="KFGQPC QUMBUL Uthmanic Script" >QUMBUL </option>
             <option value="KFGQPC SHOUBA Uthmanic Script" >SHOUBA </option>
             <option value="KFGQPC SOOSI Uthmanic Script"  >SOOSI  </option>
          </select>
       </div>
    );
 };

  return (
    <>
      <div className='col'>
        <div className='row'>
          <BtnLoad />
          <GetSafha />
          <PrevPage />
          <label> {safha} </label>
          <NextPage />
          <GetFahras />
          <BtnSave />
        </div>
        <div className='row'>
          <RiwayaSelector selectedRiwaya={riwaya} onRiwayaChange={handleRiwayaChange} />
          <FontSelector selectedFont={font} onFontChange={handleFontChange} />
        </div>
        <DisplayPage />
        {/* <div style={{ fontFamily: selectedFont }}> {page} </div> */}
        <div className='row'>
          <PrevPage />
          <NextPage />
        </div>
      </div>
    </>
  )
}

export default Explor